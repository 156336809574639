*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
.reveal{
  max-width: auto;
}

p {
  color: var(--secondary-color);
}

.intro {
  padding: 70px 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

}

h1 {
  font-size: 2.5rem;
}

.timeline {

  font-family:var(--font-family);
}

.timeline ul.timeline-list li.timeline-item {
  background: var(--secondary-color);

}

.timeline ul.timeline-list li.timeline-item {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 10px;
  background: 89afff !important;
  box-shadow: var(--custom-box-shadow);
}

.first-item {
    background-color: transparent !important;
    box-shadow: none !important;
  }

.timeline ul.timeline-list li.timeline-item::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  box-shadow: var(--custom-box-shadow);

}

.timeline ul.timeline-list li.timeline-item div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: var(--tertiary-color);
  box-shadow: var(--custom-box-shadow);

}

.timeline ul.timeline-list li.timeline-item div::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul.timeline-list li.timeline-item:nth-child(odd) div {
  left: 45px;
}

.timeline ul.timeline-list li.timeline-item:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent var(--tertiary-color) transparent transparent;
}

.timeline ul.timeline-list li.timeline-item:nth-child(even) div {
  left: -439px;
}

.timeline ul.timeline-list li.timeline-item:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent var(--tertiary-color);
}

.phase {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
  color:var(--secondary-color);

}

.timeline ul.timeline-list li.timeline-item::after {
  transition: 0.5s ease-in-out;
}

.timeline ul.timeline-list li.timeline-item.in-view::after {
  background: var(--tertiary-color);
}

.timeline ul.timeline-list li.timeline-item div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul.timeline-list li.timeline-item:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul.timeline-list li.timeline-item:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul.timeline-list li.timeline-item.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* New styles for inner ul and li */
.timeline ul.timeline-list li.timeline-item ul,
.timeline ul.timeline-list li.timeline-item li {
  list-style-type: initial;
  position: static;
  width: auto;
  margin: 0;
  padding: 0;
  background: none;
  box-shadow: none;
}

.timeline ul.timeline-list li.timeline-item ul li::after {
  content: none;
}

.timeline ul.timeline-list li.timeline-item ul li div {
  position: static;
  width: auto;
  padding: 0;
  background: none;
  box-shadow: none;
}

.timeline ul.timeline-list li.timeline-item ul li div::before {
  content: none;
}

@media screen and (max-width: 900px) {
  .timeline ul.timeline-list li.timeline-item div {
    width: 250px;
  }
  .timeline ul.timeline-list li.timeline-item:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 600px) {
  .timeline ul.timeline-list li.timeline-item {
    margin-left: 20px;
  }
  .timeline ul.timeline-list li.timeline-item div {
    width: calc(100vw - 91px);
  }
  .timeline ul.timeline-list li.timeline-item:nth-child(even) div {
    left: 45px;
  }
  .timeline ul.timeline-list li.timeline-item:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent var(--tertiary-color) transparent transparent;
  }
}

.blurry-text {
  text-shadow: 0 0 10px var(--tag-text-color);
  color: transparent;
}

.expandButton {
    color: var(--tag-text-color);
    background-color: var(--secondary-color);
    border: 1px solid var(--tertiary-color); /* Use a single border color */
    margin-left: 5px;
    padding: 1px; /* Add some padding for spacing */
    box-shadow: var(--custom-box-shadow);

  }

  .detailedBullets {
    color: var(--secondary-color);
    list-style-type: none; /* Remove default list-style */
    padding-left: 1rem !important;
    padding-top: 1rem !important;

    padding-bottom: 1rem !important;

}
