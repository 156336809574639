
  .onchain-image {
    max-height: 200px;
    object-fit: contain;
    border-radius:0.8rem;
    border: 1px solid black;

    /* border: 1px solid black; */
    backdrop-filter: blur(5px); /* Add this line */
    z-index: -1;
  }
  .fire-text-fire-container {
    padding-top: 2rem;
    padding-bottom: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    white-space: nowrap; /* Prevent text wrapping */
  }
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25vh;
    width: 100%;
  }
  
  .fire-container {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  
  .fire-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60%;
    max-height: 60%;
    z-index: 5;
    pointer-events: none;
  }
  
  @keyframes scaleUpDown {
    0%, 100% { transform: scaleY(1) scaleX(1); }
    50%, 90% { transform: scaleY(1.1); }
    75% { transform: scaleY(0.95); }
    80% { transform: scaleX(0.95); }
  }
  
  @keyframes shake {
    0%, 100% { transform: skewX(0) scale(1); }
    50% { transform: skewX(5deg) scale(0.9); }
  }
  
  @keyframes particleUp {
    0% { opacity: 0; }
    20% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; top: -100%; transform: scale(0.5); }
  }
  
  @keyframes glow {
    0%, 100% { background-color: #ef5a00; }
    50% { background-color: #ff7800; }
  }
  
  .fire-center {
    position: relative;
    height: 100%;
    width: 100%;
    animation: scaleUpDown 3s ease-out infinite;
  }
  
  .fire-center .main-fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(farthest-corner at 10px 0, #d43300 0%, #ef5a00 95%);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }
  
  .fire-center .particle-fire {
    position: absolute;
    top: 60%;
    left: 45%;
    width: 10px;
    height: 10px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out infinite;
  }
  
  .fire-right {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: shake 2s ease-out infinite;
  }
  
  .fire-right .main-fire {
    position: absolute;
    top: 15%;
    right: -25%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }
  
  .fire-right .particle-fire {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out infinite;
  }
  
  .fire-left {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: shake 3s ease-out infinite;
  }
  
  .fire-left .main-fire {
    position: absolute;
    top: 15%;
    left: -20%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }
  
  .fire-left .particle-fire {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 10%;
    height: 10%;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 3s infinite ease-out;
  }
  
  .fire-bottom .main-fire {
    position: absolute;
    top: 30%;
    left: 20%;
    width: 75%;
    height: 75%;
    background-color: #ff7800;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 100% 40%;
    filter: blur(10px);
    animation: glow 2s ease-out infinite;
  }

  /* Media query for mobile devices */
@media (max-width: 768px) {
    .fire-container {
      width: 60px;
      height: 60px;
    }
    .onchain-image {
        max-height: 100px;
      }
    .page-container {
        min-height: 15vh;
      }
  
    .fire-center .particle-fire {
      width: 6px;
      height: 6px;
    }
  
    .fire-right .particle-fire {
      width: 9px;
      height: 9px;
    }
  
    .fire-left .particle-fire {
      width: 6%;
      height: 6%;
    }
  
    .fire-bottom .main-fire {
      filter: blur(6px);
    }
  
    .fire-text-fire-container {
      align-items: center;
    }
  
    .fire-text {
      margin: 10px 0;
      font-size: 18px;
    }
  }
  
  /* Extra small devices */
  @media (max-width: 480px) {
    .fire-container {
      width: 50px;
      height: 50px;
    }
    .onchain-image {
        max-height: 100px;
      }
    .page-container {
        min-height: 10vh;
      }
    .fire-center .particle-fire {
      width: 5px;
      height: 5px;
    }
  
    .fire-right .particle-fire {
      width: 7px;
      height: 7px;
    }
  
    .fire-left .particle-fire {
      width: 5%;
      height: 5%;
    }
  
    .fire-bottom .main-fire {
      filter: blur(5px);
    }
  
    .fire-text {
      font-size: 16px;
    }
  }