@keyframes shake {
    0%, 100% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(-2px, 1px) rotate(-1deg); }
    50% { transform: translate(2px, -1px) rotate(1deg); }
    75% { transform: translate(-1px, 2px) rotate(-0.5deg); }
  }
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.15); }
    100% { transform: scale(1); }
  }
  
  .shake {
    animation: shake 0.5s infinite;
  }
  
  .pulse {
    animation: pulse 2s infinite;
  }