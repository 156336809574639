@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');

html {
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;
  background-color: rgba(14, 84, 254, 1);

}

/* Desktop background image */
body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/background4.jpg') center center no-repeat;
  background-size: cover;
  z-index: -2;
}

/* Mobile background image */
@media (max-width: 767px) {
  body::after {
    background-image: url('../assets/images/background4-mobile.jpg');
  }
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(14, 84, 254, 0.35);
  z-index: -1;
  backdrop-filter: blur(1px);

}

/* body {
  background: linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, transparent 75%), var(--primary-color);
  background-size: 60px 60px;
}  */

.description-container, .carousel-container {
  background-color: rgba(14, 84, 254, 0.75);
  padding: 20px;
  border-radius: 20px;
      border: 1px solid black;

  /* border: 1px solid black; */
  backdrop-filter: blur(5px); /* Add this line */
  & * {
    opacity: 1;
  }
}

.lottie-animation svg{
  border-radius: 10%;
  overflow: hidden;
}
/* Add this to your CSS file or within a styled component */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--tertiary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: absolute;
}


.description-text{
  color: var(--text-color);
}

.title-outline {
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    -4px -4px 0 var(--secondary-color),  
    4px -4px 0 var(--secondary-color),
    -4px 4px 0 var(--secondary-color),
    4px 4px 0 var(--secondary-color),
    -5px -5px 0 #000,  
    5px -5px 0 #000,
    -5px 5px 0 #000,
    5px 5px 0 #000;
}

.text-outline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.footer-font {
  font-family: var(--font-family);
}



@keyframes infiniteAnimation {
  0% {
    transform: rotate(0) scale(1);
  }
  25% {
    transform: rotate(-1deg) scale(1.1);
  }
  50% {
    transform: rotate(1deg) scale(1.1);
  }
  75% {
    transform: rotate(-1deg) scale(1.1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

.infinite-animation {
  animation: infiniteAnimation 5s infinite;
}

@keyframes infiniteAnimation {
  0% {
    transform: rotate(0) scale(1);
  }
  25% {
    transform: rotate(-1deg) scale(1.1);
  }
  50% {
    transform: rotate(1deg) scale(1.1);
  }
  75% {
    transform: rotate(-1deg) scale(1.1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

/* styles.css */
