

@keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(100%); /* Start from the right side of the screen */
  
    }
    100% {
      opacity: 1;
      transform: translateX(0); /* End at the normal position */
  
    }
  }
  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(100%); /* Start from the right side of the screen */
  
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the normal position */
  
    }
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%); /* Start from the right side of the screen */
  
    }
    100% {
      opacity: 1;
      transform: translateX(0); /* End at the normal position */
  
    }
  }
  
  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(-100%); /* Start from the right side of the screen */
  
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the normal position */
  
    }
  }
  