:root {
    --header-footer-color: #FFFAE6;
    --primary-color: #174887;
    --secondary-color: #FF9F66;
    --tertiary-color: #FF6B31;

    --header-footer-text-color: black;
    --text-color: #FF9F66;
    --team-text-color:black;
    --link-color: black;
    --tag-text-color:black;
    --custom-border: border: 1px solid black;


    --title-text-color:#E6FF94;
    --custom-box-shadow: 0 1px 0 0 #000, 0 -1px 0 0 #000, 1px 0 0 0 #000, -1px 0 0 0 #000;
    --font-family: 'Bowlby One', sans-serif;
    --font-family-slider: 'Roboto', sans-serif;
    --text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    --fa-border-color: black;
    --fa-border-color-hover: black;
  }
  