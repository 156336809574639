@keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

.loop-slider {

    .inner {
      display: flex;
      width: fit-content;
      animation-name: loop;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: var(--direction);
      animation-duration: var(--duration);
    }
    
  }
  .tag {

    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    font-size: 0.9rem;
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem; 
    white-space: nowrap; /* Add this line */
    span {
      font-size: 1.2rem;
    }
    background-color: var(--tertiary-color);
    border: 1px solid black;
    backdrop-filter: blur(1px); /* Add this line */
    & * {
      opacity: 1;
    }
  }
  

.tag-list-info {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding-top:  1rem ;
    overflow: hidden;
    margin: 0 auto; /* Add this line to center horizontally */
    padding-bottom: 1rem;
    background-color: rgba(14, 84, 254, 0.75);
    border: 1px solid black;
    backdrop-filter: blur(4px); /* Add this line */

  }
